import { useFeatureFlags } from '@capturi/feature-flags'
import {
  DEFAULT_TEXT_STATE,
  SegmentStatesProvider,
  useSegmentStatesContext,
} from '@capturi/filters'
import { ErrorBoundary, withSuspense } from '@capturi/react-utils'
import { getErrorMessage } from '@capturi/request'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { Stack, Text } from '@chakra-ui/react'
import { DevTool } from '@hookform/devtools'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import React, { useState } from 'react'
import { useFormContext, useFormState } from 'react-hook-form'

import { DashboardAdminEvent, logEvent } from '../../events'
import { useDashboard } from '../../hooks'
import { useInaccessibleFilterValues } from '../../hooks/useInaccessibleFilterValues'
import * as msgs from '../../messages'
import { WidgetCreateEditResult, WidgetModel, WidgetType } from '../../types'
import { isDashboardUpgraded } from '../../utils/constants'
import { ConfiguratorContext } from '../../widgets/configurator'
import { FormModel, toWidgetModel } from '../../widgets/configurator/formUtils'
import Registry from '../../widgets/registry'

type CreateWidgetDialogProps = {
  dashboardUid: string
} & BaseModalComponentProps<WidgetCreateEditResult>

const CreateWidgetDialog: React.FC<CreateWidgetDialogProps> = ({
  dashboardUid,
  isOpen = false,
  onClose,
  onSubmit,
}) => {
  const { dashboard, actions, findWidgetPosition } = useDashboard({
    uid: dashboardUid,
  })
  const toast = useToast()

  const [widgetType, setWidgetType] = useState<WidgetType>('insights_share')
  const { states } = useSegmentStatesContext()

  const definition = Registry.get(widgetType)
  const Configurator = definition?.Configurator

  const initialFormModel: FormModel<unknown> = {
    type: widgetType,
  }

  const onCreateWidget = async (
    values: FormModel<WidgetModel>,
  ): Promise<void> => {
    const definition = Registry.get(values.type)

    const widgetModel = toWidgetModel(values, states[0].channel)

    const defaultWidget = {
      visual: definition.defaultVisual,
      ...widgetModel,
      type: values.type,
      previousPeriodTrendInterval:
        widgetModel.previousPeriodTrendInterval || null,
    }

    const newWidget =
      states[0].channel === 'phone'
        ? {
            ...defaultWidget,
            filters: defaultWidget.filters ?? null,
            shareFilter: defaultWidget.shareFilter ?? null,

            textFilters: null,
            textShareFilter: null,
          }
        : {
            ...defaultWidget,
            filters: null,
            shareFilter: null,
            // textFilters can be either a saved filter uid or an object with values
            textFilters: states[0].savedTextFilter?.uid
              ? {
                  savedCaseFilterUid: states[0].savedTextFilter?.uid,
                }
              : { ...(states[0].values ?? DEFAULT_TEXT_STATE) },
            // textShareFilters can be either a saved filter uid or an object with values
            textShareFilter: states[0].subFilterState?.savedTextFilter?.uid
              ? {
                  savedCaseFilterUid:
                    states[0].subFilterState.savedTextFilter.uid,
                }
              : { ...(states[0].subFilterState?.values ?? DEFAULT_TEXT_STATE) },
          }

    const dashboardUpgraded = isDashboardUpgraded(dashboard)
    const initialSize = dashboardUpgraded
      ? definition.initialSize.upgraded
      : definition.initialSize.unupgraded
    const size = {
      width: initialSize.width,
      height: initialSize.height,
    }

    const position = findWidgetPosition(size.width, size.height)

    if (position === undefined) {
      logEvent(DashboardAdminEvent.InsufficientSpace, {
        widgetType: values.type,
        action: 'create-widget-dialog__save-widget',
      })
      toast({
        title: i18n._(msgs.InsufficientSpace),
        description: i18n._(msgs.MakeMoreSpaceForWidget(definition)),
        status: 'warning',
      })
      return
    }

    try {
      const resp = await actions.addWidget({
        ...newWidget,
        size,
        position,
      })
      onSubmit?.(resp)
    } catch (error) {
      const errorMsg = getErrorMessage(error)

      toast({
        title: t`The widget was not added`,
        description: errorMsg,
        status: 'error',
      })
    }
  }

  const context = {
    isEditMode: false,
    setType: setWidgetType,
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader borderBottom="1px solid" borderColor="gray.200">
            <Trans>Add widget</Trans>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p="0">
            <ErrorBoundary>
              <React.Suspense
                fallback={<Spinner display="block" m="4rem auto" />}
              >
                <ConfiguratorContext.Provider value={context}>
                  {definition && Configurator && (
                    <Configurator
                      formModel={initialFormModel}
                      definition={definition}
                      onSubmit={onCreateWidget}
                    >
                      <FormActions onCancel={onClose} />
                    </Configurator>
                  )}
                </ConfiguratorContext.Provider>
              </React.Suspense>
            </ErrorBoundary>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

const FormActions: React.FC<{ onCancel: () => void }> = ({ onCancel }) => {
  const { isSubmitting } = useFormState()
  const { control } = useFormContext()

  const { isInvalid } = useInaccessibleFilterValues()
  return (
    <>
      {isInvalid && (
        <Text fontStyle="italic" mt={5} ml={5} color="warning" float="left">
          *You need to remove one or more filters before being able to save
          changes.
        </Text>
      )}
      <Stack isInline spacing={4} justify="flex-end" p={4}>
        <Button secondary type="button" onClick={onCancel}>
          <Trans>Cancel</Trans>
        </Button>
        <Button primary type="submit" isDisabled={isSubmitting || isInvalid}>
          <Trans>Add widget</Trans>
        </Button>
        {process.env.NODE_ENV === 'development' && (
          <DevTool control={control} placement="top-right" />
        )}
      </Stack>
    </>
  )
}

const WidgetDialogWrapper: React.FC<CreateWidgetDialogProps> = (props) => {
  const { useEmailChannelAsDefault } = useFeatureFlags()

  return (
    <SegmentStatesProvider
      initialState={{
        channel: useEmailChannelAsDefault ? 'email' : 'phone',
      }}
    >
      <CreateWidgetDialog {...props} />
    </SegmentStatesProvider>
  )
}

export default withSuspense(WidgetDialogWrapper)
