import {
  Box,
  BoxProps,
  ButtonProps,
  Icon,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuList,
  MenuProps,
  chakra,
} from '@chakra-ui/react'
import { Trans, select } from '@lingui/macro'
import noop from 'lodash/noop'
import React from 'react'
import { MdChevronRight, MdFormatColorFill } from 'react-icons/md'

import { WidgetBackgroundColorName, colors } from '../../widgets/colors'

const localizeColor = (colorName: WidgetBackgroundColorName): string =>
  select(colorName, {
    default: 'default',
    blue: 'blue',
    sand: 'sand',
    green: 'green',
    yellow: 'yellow',
    red: 'red',
    transparent: 'transparent',
    other: 'default',
  })

export type ColorValue = { name: WidgetBackgroundColorName; value: string }
export type SelectColorFn = (color: ColorValue | undefined) => void
const BackgroundColorMenuContext = React.createContext<SelectColorFn>(noop)

export const BackgroundColorSubMenuItem: React.FC<{
  onSelectColor: SelectColorFn
  onClose?: MenuProps['onClose']
}> = ({ onSelectColor, onClose, ...props }) => {
  return (
    <BackgroundColorMenuContext.Provider
      value={(color) => {
        onSelectColor(color)
        onClose?.()
      }}
    >
      <MenuItem as={BackgroundColorMenu} closeOnSelect={false} {...props} />
    </BackgroundColorMenuContext.Provider>
  )
}

const SubMenuButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function SubMenuButtonWithRef({ children, ...props }, ref) {
    return (
      <chakra.button ref={ref} {...props}>
        {children}
        <Icon as={MdChevronRight} boxSize={4} ml="auto" />
      </chakra.button>
    )
  },
)

const ColorIcon: React.FC<{ color: string } & Omit<BoxProps, 'color'>> = ({
  color,
  ...props
}) => {
  return (
    <Box
      backgroundColor={color}
      borderRadius="100%"
      border="1px"
      borderColor={color === 'transparent' ? 'gray.300 ' : 'black'}
      boxSize={3}
      {...props}
    />
  )
}

const BackgroundColorMenu = React.forwardRef<
  HTMLButtonElement,
  MenuButtonProps
>(function BackgroundColorMenuWithRef(props, ref) {
  const onSelectColor = React.useContext(BackgroundColorMenuContext)
  return (
    <Menu>
      <MenuButton as={SubMenuButton} ref={ref} {...props}>
        <MenuItem as="div" p={0} icon={<MdFormatColorFill />}>
          <Trans>Background color</Trans>
        </MenuItem>
      </MenuButton>
      <MenuList minW="auto">
        {Object.entries(colors).map(([name, value]) => {
          const colorName = name as WidgetBackgroundColorName
          return (
            <MenuItem
              key={name}
              icon={<ColorIcon color={value} />}
              onClick={() => {
                onSelectColor({ name: colorName, value })
              }}
            >
              {localizeColor(colorName)}
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
})
