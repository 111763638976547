export default `
  .react-grid-layout .react-grid-item.react-grid-placeholder {
    background: #004EFC;
  }
  .react-grid-layout .react-grid-item .react-resizable-handle {
    opacity: 0.3;
  }
  .react-grid-layout .react-grid-item:hover .react-resizable-handle {
    opacity: 1;
  }
`
