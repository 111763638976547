import { Channel } from '@capturi/filters'

import { WidgetModel, WidgetType } from '../../types'

export type FormModel<T> = Partial<T> & {
  type: WidgetType
}

export function toFormModel<T extends WidgetModel>(
  widgetModel: T,
): FormModel<T> {
  const formModel: FormModel<T> = {
    ...widgetModel,
    previousPeriodTrendInterval: (() => {
      if (
        widgetModel.previousPeriodTrendInterval == null &&
        widgetModel.showPreviousPeriodTrendIndicator
      )
        return 'Trend'
      return widgetModel.previousPeriodTrendInterval
    })(),
  }

  return formModel
}

export function toWidgetModel(
  formModel: FormModel<WidgetModel>,
  channel?: Channel | string,
): Partial<WidgetModel> {
  const benchmarkPeriod = formModel.previousPeriodTrendInterval
  const isBenchmarkSelected = !(benchmarkPeriod === 'None')
  const selectedBenchmark =
    benchmarkPeriod === 'Trend' || benchmarkPeriod === 'None'
      ? null
      : benchmarkPeriod
  const model = {
    ...formModel,
    filters: channel === 'phone' ? formModel.filters : null,
    shareFilter: channel === 'phone' ? formModel.shareFilter : null,
    textFilters: channel === 'email' ? formModel.textFilters : null,
    textShareFilter: channel === 'email' ? formModel.textShareFilter : null,
    segmentation: undefined,
    segmentationSubFilter: undefined,
    previousPeriodTrendInterval: selectedBenchmark,
    showPreviousPeriodTrendIndicator: isBenchmarkSelected,
  }
  return model
}
