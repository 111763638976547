import React, {
  ComponentType,
  FunctionComponent,
  Suspense,
  SuspenseProps,
} from 'react'

/**
 * Wraps the provided component in `Suspense`, with the provided fallback.
 * @param WrappedComponent The component to wrap.
 * @param fallback The component to render while loading.
 */
export function withSuspense<P extends object>(
  WrappedComponent: ComponentType<P>,
  fallback: SuspenseProps['fallback'] = null,
): FunctionComponent<P> {
  const EnhancedComponent: React.FC<P> = (props) => (
    <Suspense fallback={fallback}>
      <WrappedComponent {...props} />
    </Suspense>
  )
  return EnhancedComponent
}
