import {
  useAllSavedFilters,
  useAllSavedTextFilters,
} from '@capturi/api-filters'
import { useAllScores } from '@capturi/api-scoring'
import { useAllTrackers } from '@capturi/api-trackers'
import { useFeatureFlags } from '@capturi/feature-flags'
import React from 'react'
import { useWatch } from 'react-hook-form'

import { useCurrentUser } from '@capturi/core'
import { WidgetModel } from '../types'
import { FormModel } from '../widgets/configurator'

export type useInaccessibleFilterResult = {
  isInvalid: boolean
}

//Hook to checks filters/subfilters for inaccessible entities (scores, trackers etc) and update ui accordingly
export function useInaccessibleFilterValues(): useInaccessibleFilterResult {
  const filters = useWatch<FormModel<WidgetModel>, 'filters'>({
    name: 'filters',
  })
  const shareFilters = useWatch<FormModel<WidgetModel>, 'shareFilter'>({
    name: 'shareFilter',
  })
  const textFilters = useWatch<FormModel<WidgetModel>, 'textFilters'>({
    name: 'textFilters',
  })
  const textShareFilter = useWatch<FormModel<WidgetModel>, 'textShareFilter'>({
    name: 'textShareFilter',
  })
  const { data: scores } = useAllScores()

  const { data: trackers } = useAllTrackers()
  const { data: savedFilters } = useAllSavedFilters()

  const { enableText } = useFeatureFlags()
  const { permissions } = useCurrentUser()
  const { data: savedTextFilters } = useAllSavedTextFilters({
    enabled: enableText && permissions.text,
  })

  const {
    savedFilter,
    savedShareFilter,
    savedTextFilter,
    savedTextShareFilter,
  } = React.useMemo(() => {
    const savedFilter = filters?.savedFilterGroupUid
      ? savedFilters?.find((x) => x.uid === filters.savedFilterGroupUid)
      : undefined
    const savedShareFilter = shareFilters?.savedFilterGroupUid
      ? savedFilters?.find((x) => x.uid === shareFilters.savedFilterGroupUid)
      : undefined
    const savedTextFilter = textFilters?.savedCaseFilterUid
      ? savedTextFilters?.find((x) => x.uid === textFilters.savedCaseFilterUid)
      : undefined
    const savedTextShareFilter = textShareFilter?.savedCaseFilterUid
      ? savedTextFilters?.find(
          (x) => x.uid === textShareFilter.savedCaseFilterUid,
        )
      : undefined
    return {
      savedFilter,
      savedShareFilter,
      savedTextFilter,
      savedTextShareFilter,
    }
  }, [
    filters?.savedFilterGroupUid,
    shareFilters?.savedFilterGroupUid,
    textFilters?.savedCaseFilterUid,
    textShareFilter?.savedCaseFilterUid,
    savedFilters,
    savedTextFilters,
  ])

  const notAllowedTrackers = React.useMemo(() => {
    if (trackers === undefined) return new Set()

    return trackers.reduce((set, t) => {
      if (t.accessLevel === 'None') {
        set.add(t.uid)
      }
      return set
    }, new Set())
  }, [trackers])

  return React.useMemo(() => {
    let isInvalid = false

    const containsInacessibleSegment = savedFilter?.accessLevel === 'None'
    const containsInacessibleShareSegment =
      savedShareFilter?.accessLevel === 'None'
    const containsInacessibleTextSegment =
      savedTextFilter?.accessLevel === 'None'
    const containsInacessibleTextShareSegment =
      savedTextShareFilter?.accessLevel === 'None'

    const segmentContainsInaccessibleScore = (filters?.scores ?? []).some(
      (item) => {
        const score = scores?.find((x) => x.uid === item.uid)
        return score && score.accessLevel === 'None'
      },
    )

    const filterContainsInaccessibleScore = (shareFilters?.scores ?? []).some(
      (item) => {
        const score = scores?.find((x) => x.uid === item.uid)
        return score && score.accessLevel === 'None'
      },
    )

    const segmentContainsInacessibleTracker = filters?.trackers?.some((t) =>
      t?.uids?.some((u: string) => notAllowedTrackers.has(u)),
    )

    const filterContainsInacessibleTracker = shareFilters?.trackers?.some((t) =>
      t?.uids?.some((u: string) => notAllowedTrackers.has(u)),
    )

    const filterContainsInacessibleTextTracker = textFilters?.trackers?.some(
      (t) => t?.uids?.some((u: string) => notAllowedTrackers.has(u)),
    )

    const subFilterContainsInacessibleTextTracker =
      textShareFilter?.trackers?.some((t) =>
        t?.uids?.some((u: string) => notAllowedTrackers.has(u)),
      )

    const segmentContainsInacessibleTrackerNotListen =
      filters?.notTrackers?.some((t) =>
        t?.uids?.some((u: string) => notAllowedTrackers.has(u)),
      )
    const filterContainsInacessibleTrackerNotListen =
      filters?.notTrackers?.some((t) =>
        t?.uids?.some((u: string) => notAllowedTrackers.has(u)),
      )

    isInvalid =
      !!segmentContainsInacessibleTrackerNotListen ||
      filterContainsInacessibleTrackerNotListen ||
      !!segmentContainsInacessibleTracker ||
      filterContainsInacessibleTracker ||
      filterContainsInacessibleTextTracker ||
      subFilterContainsInacessibleTextTracker ||
      containsInacessibleSegment ||
      containsInacessibleShareSegment ||
      containsInacessibleTextSegment ||
      containsInacessibleTextShareSegment ||
      segmentContainsInaccessibleScore ||
      filterContainsInaccessibleScore

    return {
      isInvalid,
    }
  }, [
    savedFilter?.accessLevel,
    savedShareFilter?.accessLevel,
    savedTextFilter?.accessLevel,
    savedTextShareFilter?.accessLevel,
    filters?.scores,
    filters?.trackers,
    filters?.notTrackers,
    shareFilters?.scores,
    shareFilters?.trackers,
    textFilters?.trackers,
    textShareFilter?.trackers,
    scores,
    notAllowedTrackers,
  ])
}
