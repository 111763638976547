import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import analytics from '@capturi/analytics'
import {
  FilterPeriodSelectContainer,
  useFilterPeriodContext,
} from '@capturi/filters'
import { ErrorBoundary } from '@capturi/react-utils'
import { Button, Spinner, useToast } from '@capturi/ui-components'
import { useConfirm } from '@capturi/use-confirm'
import { useModal } from '@capturi/use-modal'
import {
  Box,
  ButtonGroup,
  Center,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { Global, css } from '@emotion/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import React, { useCallback, useState } from 'react'
import { ItemCallback, Layout } from 'react-grid-layout'
import {
  MdContentCopy,
  MdDelete,
  MdFullscreen,
  MdFullscreenExit,
  MdMoreHoriz,
  MdSettings,
} from 'react-icons/md'
import { useFullscreen, useToggle } from 'react-use'
import screenfull from 'screenfull'

import { CloneDashboardDialog } from '../components/CloneDashboardDialog'
import { CreateUpdateDashboardDialog } from '../components/CreateUpdateDashboardDialog'
import { DashboardCanvas } from '../components/DashboardCanvas'
import { DashboardSelectPopover } from '../components/DashboardSelectPopover'
import { SharedContextDialog } from '../components/SharedContextDialog'
import CreateWidgetDialog from '../components/widgets/CreateWidgetDialog'
import EditWidgetDialog from '../components/widgets/EditWidgetDialog'
import EditableWidget from '../components/widgets/EditableWidget'
import { SharedContextProvider } from '../contexts/ContextualDashboardContext'
import { DashboardContext } from '../contexts/DashboardContext'
import {
  DashboardAdminEvent,
  DashboardEvent,
  WidgetEvent,
  logEvent,
  logWidgetEvent,
} from '../events'
import { useDashboard } from '../hooks/useDashboard'
import useLayouts from '../hooks/useLayout'
import useOnlyWeekdaysToggle from '../hooks/useOnlyWeekdaysToggle'
import * as msgs from '../messages'
import {
  BaseDashboard,
  CreateDashboardModel,
  CreateDashboardRequestModel,
  Dashboard,
  WidgetModel,
} from '../types'
import {
  UPGRADED_DASHBOARD_COLUMNS,
  UPGRADED_DASHBOARD_ROWS,
  isDashboardUpgraded,
} from '../utils/constants'
import { WidgetBackgroundColorName } from '../widgets/colors'
import Registry from '../widgets/registry'
import CancelActionModal from './CancelActionModal'
import DashedLine from './DashedLine'
import RGLCSSOverride from './rgl-css-override'

type Props = {
  uid: string
  onDashboardDeleted: () => void
  onDashboardNotFound: () => void
  onDashboardCloned: (dashboard: Dashboard) => void
  onDashboardChange: (dashboard: BaseDashboard) => void
}

const DashboardAdminView: React.FC<Props> = ({
  onDashboardDeleted,
  onDashboardNotFound,
  onDashboardCloned,
  onDashboardChange,
}) => {
  const confirm = useConfirm()
  const toast = useToast()
  const { periodDef } = useFilterPeriodContext()

  const [openEditWidgetDialog] = useModal(EditWidgetDialog)
  const [openCreateWidgetDialog] = useModal(CreateWidgetDialog)
  const [openCloneDashboardDialog] = useModal(CloneDashboardDialog)
  const {
    isOpen: dashboardDialogIsOpen,
    onOpen: openDashboardDialog,
    onClose: closeDashboardDialog,
  } = useDisclosure()

  const {
    dashboard,
    widgets,
    actions,
    refreshDashboard,
    findWidgetPosition,
    getWidget,
  } = useDashboard({
    onDashboardNotFound,
  })

  const layouts = useLayouts(widgets, false)
  const [showGrid, setShowGrid] = useState(false)
  const [isDraggingWidget, setIsDraggingWidget] = useState(false)
  const [, PlotOnlyWeekdaysToggle] = useOnlyWeekdaysToggle()

  const handleDeleteDashboard = async (dashboard: Dashboard): Promise<void> => {
    try {
      await actions.deleteDashboard()
      toast({
        title: t`Dashboard deleted`,
        status: 'success',
      })
      onDashboardDeleted()
    } catch (e) {
      toast({
        title: t`An error occurred`,
        description: t`The dashboard "${dashboard.title}" was not deleted`,
        status: 'error',
      })
      throw e
    }
  }

  const onAddWidget = async (): Promise<void> => {
    if (dashboard == null) return
    logEvent(DashboardAdminEvent.OpenCreateWidgetDialog)
    const dashboardHasSpace = Registry.getAll().some((x) => {
      return findWidgetPosition(x.minWidth, x.minHeight) !== undefined
    })
    if (!dashboardHasSpace) {
      logEvent(DashboardAdminEvent.InsufficientSpace, {
        action: 'add-widget-clicked',
      })
      toast({
        title: i18n._(msgs.InsufficientSpace),
        description: i18n._(msgs.InsufficientSpace_Desc),
        status: 'warning',
      })
      return
    }
    openCreateWidgetDialog({
      dashboardUid: dashboard.uid,
      onSubmit: (widget) => {
        logWidgetEvent(WidgetEvent.WidgetAdded, widget, {
          totalWidgets: widgets.length + 1,
        })
        refreshDashboard()
      },
    })
  }

  const onDeleteWidget = useCallback(
    async (widget: WidgetModel): Promise<void> => {
      try {
        await confirm({
          title: t`Are you sure?`,
          description: t`Are you sure you want to delete the widget "${widget.title}"?`,
          confirmText: t`Delete`,
          colorScheme: 'red',
        })
        logWidgetEvent(WidgetEvent.DeleteWidget, widget)
        try {
          await actions.deleteWidget(widget.uid)
          toast({
            title: t`Widget deleted`,
            status: 'success',
          })
        } catch (_e) {
          toast({
            title: t`An error occurred`,
            description: t`The widget "${widget.title}" was not deleted`,
            status: 'error',
          })
        }
      } catch {
        // Cancelled
      }
    },
    [actions, confirm, toast],
  )

  const onCloneWidget = useCallback(
    async (widget: WidgetModel): Promise<void> => {
      const widgetCopy = { ...widget, uid: undefined } as Omit<
        WidgetModel,
        'uid'
      >
      try {
        const definition = Registry.get(widget.type)
        // Attempt to find a position with same size widget
        let size = widgetCopy.size
        let position = findWidgetPosition(size.width, size.height)
        if (
          position === undefined &&
          (size.width !== definition.minWidth ||
            size.height !== definition.minHeight)
        ) {
          // If unsuccessful, then try again with minimum accepted width/height
          position = findWidgetPosition(
            definition.minWidth,
            definition.minHeight,
          )
          if (position !== undefined) {
            size = {
              width: definition.minWidth,
              height: definition.minHeight,
            }
          }
        }
        if (position === undefined) {
          logEvent(DashboardAdminEvent.InsufficientSpace, {
            widgetType: widget.type,
            action: 'clone-widget',
          })
          toast({
            title: i18n._(msgs.InsufficientSpace),
            description: i18n._(msgs.MakeMoreSpaceForWidget(definition)),
            status: 'warning',
          })
          return
        }
        logWidgetEvent(WidgetEvent.CloneWidget, widgetCopy)
        await actions.addWidget({
          ...widgetCopy,
          title: t`Copy of ${widget.title}`,
          position,
          size,
        })
        toast({
          title: t`Widget cloned`,
          status: 'success',
        })
      } catch (_error) {
        toast({
          title: t`Widget was not cloned`,
          status: 'warning',
        })
      }
    },
    [actions, findWidgetPosition, toast],
  )

  const onEditWidget = useCallback(
    (widget: WidgetModel): void => {
      if (dashboard == null) return
      logWidgetEvent(WidgetEvent.OpenEditDialog, widget)
      openEditWidgetDialog({
        dashboardUid: dashboard.uid,
        widget,
        onSubmit: (updatedWidget) => {
          logWidgetEvent(WidgetEvent.WidgetEdited, updatedWidget)
          refreshDashboard()
        },
      })
    },
    [dashboard, openEditWidgetDialog, refreshDashboard],
  )

  const onEditDashboard = (): void => {
    refreshDashboard() // Refresh dashboard to ensure swr cache is not stale
    if (dashboard == null) return
    logEvent(DashboardAdminEvent.OpenEditDialog)
    openDashboardDialog()
  }

  const onCloneDashboard = (): void => {
    if (dashboard == null) return
    logEvent(DashboardAdminEvent.OpenCloneDialog)
    openCloneDashboardDialog({
      defaultTitle: `${dashboard.title} (Copy)`,
      defaultDescription: dashboard.description,
      onSubmit: async (result) => {
        try {
          const newDashboard = await actions.cloneDashboard(
            result.title,
            result.description,
          )
          logEvent(DashboardAdminEvent.DashboardCloned)
          onDashboardCloned(newDashboard)
          toast({
            title: t`Dashboard cloned`,
            status: 'success',
          })
        } catch {
          toast({
            title: t`An error occurred`,
            description: t`The dashboard was not cloned`,
            status: 'error',
          })
        }
      },
    })
  }

  const onDeleteDashboard = async (): Promise<void> => {
    if (dashboard == null) return
    try {
      await confirm({
        title: t`Are you sure you want to delete the dashboard?`,
        confirmText: t`Delete`,
        colorScheme: 'red',
      })
      logEvent(DashboardAdminEvent.DeleteDashboard)
      handleDeleteDashboard(dashboard)
    } catch {
      // Cancelled
    }
  }

  const onUpdateWidgetLayout = async (
    layout: Layout,
    widget: WidgetModel,
  ): Promise<void> => {
    try {
      await actions.updateWidget(widget.uid, {
        type: widget.type,
        size: {
          width: layout.w,
          height: layout.h,
        },
        position: {
          x: layout.x,
          y: layout.y,
        },
      })
    } catch {
      toast({
        title: t`Widget update failed`,
        status: 'error',
      })
    }
  }

  const onWidgetResized: ItemCallback = (_layout, oldItem, newItem) => {
    const widget = getWidget(newItem.i)
    if (widget == null) {
      return
    }
    if (newItem.w !== oldItem.w || newItem.h !== oldItem.h) {
      const newArea = newItem.w * newItem.h
      const oldArea = oldItem.w * oldItem.h
      logWidgetEvent(WidgetEvent.WidgetResized, widget, {
        width: newItem.w,
        height: newItem.h,
        widthDelta: newItem.w - oldItem.w,
        heightDelta: newItem.h - oldItem.h,
        areaIncreased: newArea > oldArea,
      })
      if (newItem.h + newItem.y > UPGRADED_DASHBOARD_ROWS) {
        analytics.event('dashboard_widgets_WidgetUnderFold')
      }
      onUpdateWidgetLayout(newItem, widget)
    }
  }

  const onWidgetMoved: ItemCallback = (_layout, oldItem, newItem) => {
    const widget = getWidget(newItem.i)
    if (widget == null) {
      return
    }
    if (newItem.x !== oldItem.x || newItem.y !== oldItem.y) {
      logWidgetEvent(WidgetEvent.WidgetMoved, widget)
      if (newItem.h + newItem.y > UPGRADED_DASHBOARD_ROWS) {
        analytics.event('dashboard_widgets_WidgetUnderFold')
      }
      onUpdateWidgetLayout(newItem, widget)
    }
  }

  const onChangeWidgetColor = async (
    widget: WidgetModel,
    color?: { name: WidgetBackgroundColorName },
  ): Promise<void> => {
    try {
      await actions.updateWidget(widget.uid, {
        type: widget.type,
        backgroundColor: color?.name,
      })
    } catch {
      toast({
        title: t`Widget update failed`,
        status: 'error',
      })
    }
  }

  const documentRef = React.useRef<HTMLDivElement>(null)
  const [isFullscreenShowing, toggleFullscreen] = useToggle(false)
  const canEdit = dashboard?.accessLevel === 'Edit'
  const isFullscreen = useFullscreen(documentRef, isFullscreenShowing, {
    onClose: () => {
      toggleFullscreen(false)
      logEvent(DashboardEvent.LeaveFullscreen)
    },
  })

  const fullscreenLabel = isFullscreen
    ? t`Exit fullscreen mode`
    : t`View in fullscreen mode`

  const [showCancelSaveActionModal] = useModal(CancelActionModal)
  const saveDashboard = async (
    result: Partial<CreateDashboardRequestModel>,
  ): Promise<void> => {
    try {
      await actions.updateDashboard(result)
      toast({
        title: t`Dashboard updated`,
        status: 'success',
      })
      logEvent(DashboardAdminEvent.DashboardSaved, {
        permissionPreset: result.permissionPreset,
        permissionPresetChanged:
          result.permissionPreset !== dashboard?.permissionPreset,
      })
    } catch (error) {
      toast({
        title: t`An error occurred`,
        description: t`The dashboard was not updated`,
        status: 'error',
      })
      throw error
    }
  }
  const handleDashboardSave = (result: Partial<CreateDashboardModel>): void => {
    const { hasWarnings, ...requestModel } = result
    if (hasWarnings) {
      closeDashboardDialog()
      showCancelSaveActionModal({
        action: async () => {
          await saveDashboard(requestModel)
        },
      })
    } else {
      saveDashboard(requestModel)
      closeDashboardDialog()
    }
  }

  const handleUpgradeDashboardToNewSize = async (): Promise<void> => {
    try {
      await handleDashboardSave({
        rows: UPGRADED_DASHBOARD_ROWS,
        columns: UPGRADED_DASHBOARD_COLUMNS,
      })
    } catch (error) {
      toast({
        title: t`An error occurred`,
        description: t`The dashboard has not been upgraded to new size`,
        status: 'error',
      })
      throw error
    }

    try {
      const ratioX = UPGRADED_DASHBOARD_COLUMNS / 12
      const ratioY = UPGRADED_DASHBOARD_ROWS / 8
      widgets.forEach((widget) => {
        const { x, y } = widget.position
        const { width, height } = widget.size
        actions.updateWidget(
          widget.uid,
          {
            type: widget.type,
            size: {
              width: Math.floor(width * ratioX),
              height: Math.floor(height * ratioY),
            },
            position: {
              x: Math.floor(x * ratioX),
              y: Math.floor(y * ratioY),
            },
          },
          { optimistic: true },
        )
      })
    } catch (error) {
      toast({
        title: t`An error occurred`,
        description: t`Some of the widgets failed to be resized automatically, please, consider resizing them manually`,
        status: 'error',
      })
      throw error
    }
  }

  const onUpgradeDashboard = async (): Promise<void> => {
    if (dashboard == null) return
    try {
      logEvent(DashboardAdminEvent.DashboardUpgradeDialogOpened)
      await confirm({
        title: t`Update dashboard?`,
        description: (
          <Trans>
            The upgrade lets you add more widgets vertically and achieve more
            precise widget positioning.
            <br />
            <br />
            Please note that this action might resize some of your current
            widgets. Consequently, you may need to rearrange your widgets after
            completing this step.
          </Trans>
        ),
        confirmText: t`Proceed`,
        cancelText: t`Discard`,
        colorScheme: 'primary',
      })
      logEvent(DashboardAdminEvent.DashboardUpgradeDialogProceeded)
      handleUpgradeDashboardToNewSize()
    } catch {
      // Cancelled
      logEvent(DashboardAdminEvent.DashboardUpgradeDialogDiscarded)
    }
  }

  const dashboardUpgraded = isDashboardUpgraded(dashboard)
  return (
    <SharedContextProvider defaultType={dashboard?.sharedContextType}>
      <Stack
        ref={documentRef}
        direction="column"
        h="100%"
        background={isFullscreenShowing ? 'gray.50' : 'none'}
        p={isFullscreenShowing ? '2' : 'initial'}
        width="100%"
        flex={1}
      >
        <Flex as="header" wrap="wrap" align="center">
          <Box pb="2px" maxW="100%">
            <DashboardSelectPopover
              dashboard={dashboard ?? undefined}
              onSelectDashboard={onDashboardChange}
            >
              <Heading fontSize="3xl" as="h1">
                {dashboard?.title}
              </Heading>
            </DashboardSelectPopover>
            <Text>{dashboard?.description}</Text>
          </Box>
          <Box ml="auto" display="inline-flex">
            <ButtonGroup
              spacing={2}
              size="sm"
              display={isFullscreenShowing ? 'none' : 'inline-flex'}
            >
              <SharedContextDialog />
              <Flex align="center" px={4}>
                <PlotOnlyWeekdaysToggle
                  onChange={(e) =>
                    logEvent(DashboardEvent.TogglePlotOnlyWeekdays, {
                      onlyWeekdays: e.target.checked,
                      view: 'admin',
                    })
                  }
                />
              </Flex>
              <Box>
                <FilterPeriodSelectContainer
                  onSelectPeriod={(period) =>
                    logEvent(DashboardAdminEvent.SetDatePeriod, {
                      period: period.name,
                    })
                  }
                />
              </Box>
              <Button
                leftIcon={<MdSettings />}
                onClick={() => onEditDashboard()}
              >
                <Trans>Settings</Trans>
              </Button>
              {dashboard && dashboardDialogIsOpen && (
                <CreateUpdateDashboardDialog
                  dashboard={dashboard}
                  periodDef={periodDef}
                  addAccessKey={actions.addAccessKey}
                  deleteAccessKey={actions.deleteAccessKey}
                  isOpen={dashboardDialogIsOpen}
                  onClose={closeDashboardDialog}
                  onSubmit={handleDashboardSave}
                />
              )}
              <Button primary onClick={() => onAddWidget()}>
                <Trans>Add widget</Trans>
              </Button>
              {canEdit && (
                <>
                  <Menu>
                    <MenuButton as={IconButton} p={0} icon={<MdMoreHoriz />} />
                    <Portal>
                      <MenuList>
                        <MenuItem
                          icon={<MdContentCopy />}
                          onClick={onCloneDashboard}
                        >
                          <Trans>Clone dashboard</Trans>
                        </MenuItem>
                        <MenuItem
                          icon={<MdDelete />}
                          onClick={onDeleteDashboard}
                        >
                          <Trans>Delete dashboard</Trans>
                        </MenuItem>
                      </MenuList>
                    </Portal>
                  </Menu>
                </>
              )}
            </ButtonGroup>
            {screenfull.isEnabled && (
              <Tooltip label={fullscreenLabel}>
                <IconButton ml="2" aria-label="test" onClick={toggleFullscreen}>
                  {isFullscreen ? <MdFullscreenExit /> : <MdFullscreen />}
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Flex>

        <DashboardCanvas
          columns={dashboard?.columns}
          rows={dashboard?.rows}
          layout={layouts}
          showGrid={showGrid || isDraggingWidget}
          canEditDashboard={canEdit}
          dashboardUpgraded={dashboardUpgraded}
          placeholder={t`Add a widget`}
          onDrag={(_layout, _oldItem, _newItem, _placeholder, event) => {
            /**
             * react-grid-layout@1.2.4 has a bug where `onDragStart` is fired also
             * by clicking, i.e. no dragging occured. There is an open PR but it remains open for more than a month:
             * https://github.com/react-grid-layout/react-grid-layout/pull/1411
             *
             * This is a work-around to properly be able detect that a widget is being dragged without using `onDragStart`
             */
            const isDragging = !!(event.movementX || event.movementY)
            if (isDragging) {
              setIsDraggingWidget(true)
            }
            return
          }}
          onDragStop={(...args) => {
            onWidgetMoved(...args)
            setTimeout(() => setIsDraggingWidget(false))
          }}
          onResizeStart={() => setShowGrid(true)}
          onResizeStop={(...args) => {
            onWidgetResized(...args)
            setShowGrid(false)
          }}
          compactType={null}
        >
          {widgets.map((w) => (
            <EditableWidget
              key={w.uid}
              widget={w}
              currentUserCanEdit={canEdit}
              onEdit={onEditWidget}
              onClone={onCloneWidget}
              onDelete={onDeleteWidget}
              onBackgroundChange={onChangeWidgetColor}
              dragging={showGrid || isDraggingWidget}
            />
          ))}
        </DashboardCanvas>

        {dashboard && !dashboardUpgraded && widgets.length > 0 && (
          <Flex
            align="center"
            justify="space-between"
            position="absolute"
            left={10}
            right={10}
            bottom={4}
          >
            <Box w="100%" overflow="hidden" flex="1">
              <DashedLine />
            </Box>
            <Center>
              <Button size="xs" primary onClick={onUpgradeDashboard}>
                <Trans>
                  Click to update the dashboard with latest features
                </Trans>
              </Button>
            </Center>
            <Box w="100%" overflow="hidden" flex="1">
              <DashedLine />
            </Box>
          </Flex>
        )}
      </Stack>
    </SharedContextProvider>
  )
}

export default function DashboardAdminViewWrapper(
  props: Props,
): React.ReactElement {
  return (
    <DashboardContext.Provider value={props.uid}>
      <ErrorBoundary>
        <React.Suspense fallback={<Spinner display="block" m="1rem auto" />}>
          <DashboardAdminView {...props} />
        </React.Suspense>
      </ErrorBoundary>
      <Global
        styles={css`
          ${RGLCSSOverride}
        `}
      />
    </DashboardContext.Provider>
  )
}
